<template>
  <form @submit.prevent="submit" class="form-wire" :class="{ 'form-wire__desktop': !isMobileDevice }">
    <div>
      <h1 class="headline-small">
        {{ $t('wallet.modal.wire_transfer_details.title') }}
      </h1>

      <p class="body-text-large mt-s24">
        {{ $t('wallet.modal.wire_transfer_details.description') }}
      </p>

      <div class="form-wire__input mt-s24">
        <CustomInput
          data-cy="input-account-number"
          data-testid="input-account-number"
          ref="accountNumber"
          :initialFocus="true"
          v-model="transferDetails.accountNumber"
        >
          <span class="body-text-x-large">
            {{ $t('wallet.modal.wire_transfer_details.account_number') }}
          </span>
        </CustomInput>
      </div>

      <div>
        <CustomInput
          data-cy="input-routing-number"
          data-testid="input-routing-number"
          ref="routingNumber"
          v-model="transferDetails.routingNumber"
        >
          <span class="body-text-x-large">
            {{ $t('wallet.modal.wire_transfer_details.routing_number') }}
          </span>
        </CustomInput>
      </div>
    </div>

    <div class="mt-s32">
      <ButtonV2
        data-cy="button-submit"
        data-testid="button-submit"
        type="submit"
        :inactive="inactive"
        :label="$tc('wallet.modal.wire_transfer_details.submit')"
        size="medium"
        version="primary"
        wide
      />
      <ButtonV2
        data-cy="button-close"
        data-testid="button-close"
        class="mt-s16"
        @onClick="hideModal"
        :label="$tc('navigation.close')"
        size="medium"
        version="secondary"
        wide
      />
    </div>
  </form>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2.vue';
import CustomInput from '@/components/misc/CustomInput/CustomInput.vue';
import { mapActions } from 'vuex';

export default {
  name: 'AddWireTransferDetails',
  components: {
    ButtonV2,
    CustomInput,
  },

  data() {
    return {
      transferDetails: {
        routingNumber: '',
        accountNumber: '',
      },
      inactive: true,
    };
  },

  watch: {
    'transferDetails.routingNumber'() {
      this.formCompleted();
    },
    'transferDetails.accountNumber'() {
      this.formCompleted();
    }
  },

  methods: {
    ...mapActions('wallet', ['postCreateUsaWire']),
    ...mapActions('ui', ['setIsOverlayLoading']),

    formCompleted() {
      if(this.transferDetails.routingNumber.length >= 7 && this.transferDetails.accountNumber.length >= 7) {
        return this.inactive = false;
      }
      this.inactive = true;
    },

    verifyFields(){
      const { routingNumber, accountNumber } = this.transferDetails;

      if (accountNumber.length < 7) {
        this.$refs.accountNumber.setError(
          this.$t('wallet.modal.wire_transfer_details.invalid_account_number')
        );
        return false;
      }
      this.$refs.accountNumber.setError(false);

      if (routingNumber.length < 7) {
        this.$refs.routingNumber.setError(
          this.$t('wallet.modal.wire_transfer_details.invalid_routing_number')
        );
        return false;
      }
      this.$refs.routingNumber.setError(false);
      return true;
    },

    async submit() {
      try {
        const isValid = this.verifyFields();
        
        this.inactive = true;
        this.setIsOverlayLoading(true);

        if (isValid) {
          await this.postCreateUsaWire(this.transferDetails);
          this.showModal('PaymentInterface', { isDeposit: this.isDeposit });
        }

      } finally {
        this.inactive = false;
        this.setIsOverlayLoading(false);
      }
    },
  },

  props: {
    isDeposit: {
      type: Boolean,
      default: true,
    },
  },

  async mounted() {
    await this.$store.dispatch('events/track', {
      event: 'WIRE_USER_BANK_DETAILS_VIEWED',
    });
  },
};
</script>

<style scoped>
  .form-wire {
    @apply flex flex-col justify-between max-w-full my-s12;
    width: 408px;
  }

  .form-wire__desktop {
    height: 631px;
    max-height: 80vh;
  }
</style>
